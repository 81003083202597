.inputRounded .MuiOutlinedInput-root {
    border-radius: 25px;
    background-color: white;
  }

  
.center-login {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }

  #app-table tr:nth-child(2n) {
    background-color: #e2e1e2;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ced4da !important;
  outline: none !important;
}